.main-menu {

	@media screen and (max-width: $screen-xs-max) {
		position: fixed;
		top: 0;
		bottom: 0;
		right: 0;
		width: $mobile-menu-width;
		-webkit-transform: translateX(100%);
		transform: translateX(100%);
	}
	
}
.menu-main-menu-container, .menu-secondary {

	.menu {
		padding: 0px;
	}
	.menu-item {
		display: inline-block;

		@media screen and (max-width: $screen-xs-max) {
			display: block;
			margin-left: 10px;
			text-align: left;
		}

		a {
			padding: 10px 20px;
			font-size: 1.2em;

			@media screen and (max-width: $screen-desktop) {
				font-size: 1em;
			}

			@media screen and (max-width: $screen-xs-max) {
				display: block;
				padding: 15px;
				color: #fff;
				font-size: 1.8em;
			}
		}
	}
	.current-menu-item {

		@media screen and (max-width: $screen-xs-max) {
			border-left: 4px solid #fff;
		}
	}
}

.trigger-menu-mobile {
	float: right;
	font-size: 2em;

	&:focus, &:active, &:visited, &:hover {
		color: $rose;
	}

	.fa {
		vertical-align: top;
	}
}

