.user-profile-picture {
	float: left;

	img {
		margin: 30px;
		border-radius: 50%;
	}
}
.user-profile-info {
	float: left;

}

li:nth-child(even) {
	.user-profile-picture {
	float: right;
	}
	.user-profile-info {
		float: right;
	}
}