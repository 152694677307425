/* fonts */

$title-font : 'Poiret One', cursive;

$text-font : 'Josefin Slab', serif;

$cursive-font : 'Indie Flower', cursive;

$main-font : 'Open Sans Condensed', sans-serif;

/* colors */

$red : #AB2C1C;

$red-darker : darken($red, 20%);

$red-dark : darken($red, 10%);

$red-light : lighten($red, 10%);

$red-lighter : lighten($red, 20%);


$rose : #D9718C;

$rose-darker : darken($rose, 20%);

$rose-dark : darken($rose, 10%);

$rose-light : lighten($rose, 10%);

$rose-lighter : lighten($rose, 20%);


$orange : #F26D3D;

$orange-darker : darken($orange, 20%);

$orange-dark : darken($orange, 10%);

$orange-light : lighten($orange, 10%);

$orange-lighter : lighten($orange, 20%);


$green : #86A66F;

$green-darker : darken($green, 20%);

$green-dark : darken($green, 10%);

$green-light : lighten($green, 10%);

$green-lighter : lighten($green, 20%);


$blue : #396D95;

$blue-darker : darken($blue, 20%);

$blue-dark : darken($blue, 10%);

$blue-light : lighten($blue, 10%);

$blue-lighter : lighten($blue, 20%);


$gray : gray;

$gray-darker : darken($gray, 20%);

$gray-dark : darken($gray, 10%);

$gray-light : lighten($gray, 10%);

$gray-lighter : lighten($gray, 20%);


$brown : #A66A5D;

$brown-darker : darken($brown, 20%);

$brown-dark : darken($brown, 10%);

$brown-light : lighten($brown, 10%);

$brown-lighter : lighten($brown, 20%);

/* social colors */

$facebook-color : #3b5998;

$instagram-color : #3f729b;

$tumblr-color : #35465c;

$twitter-color : #55acee;

$vimeo-color : #1ab7ea;

$linkedin-color : #4875B4;

/* position */

$header-zindex : 100;

/* width */

$mobile-menu-width: 75%;