.btn {
	padding: 3px 5px;
	text-align: center;
	color: $gray;
	border: 1px solid $gray;
	border-radius: 0;
	transition: all 0.3s ease;
}
.btn-rose {
	@extend .btn;
	color: $rose;
	border-color: $rose;

	&:hover {
		background-color: $rose;
		color: #fff;
	}
}
.btn-large {
	padding: 10px;
	font-size: 1.2em;
}
.btn-container {
	margin-top: 10px;
	margin-bottom: 10px;
}