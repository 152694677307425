/* commmon */

.hasListStyle {
	padding-left: 20px;
	list-style: circle;
	
	li {
		display: list-item;

		a {
			border: none;
		}
	}
}


.sidebar {
	& > ul {
		padding: 0px;
		list-style: none;
	}
}

.widget-container {

	margin-bottom: 30px;

	& > ul {
		padding: 0px;
		list-style: none;

		li {
			display: inline-block;
			margin-bottom: 10px;

			a {
			@extend .btn-rose;
			vertical-align: inherit;
			}
		}

	}
}
.widget-title {
	margin-bottom: 20px;
	padding: 10px;
	text-align: center;
	border: 1px solid $rose;
}

/* Recent Comments */
.widget-container.widget_recent_comments {

	& > ul {
		@extend .hasListStyle;
	}
	
}

/* Recent Entries */
.widget-container.widget_recent_entries {
	& > ul {
		@extend .hasListStyle;
	}
}

/* Tag Cloud */
.tagcloud{
	a {
		@extend .btn-rose;
	}
}