
$social-size : 50px;

.social {
	display: block;
	width: $social-size;
	height: $social-size;
	color: #fff;
	font-size: 30px;
	text-align: center;
	line-height: $social-size;

	&:hover, &:focus {
		color: #fff;
	}
}
.social.rounded {
	border-radius: 50%;
}
.facebook {
	background-color: $facebook-color;
	&:hover {
		background-color: darken($facebook-color, 10%);
	}
}

.instagram {
	background-color: $instagram-color;
	&:hover {
		background-color: darken($instagram-color, 10%);
	}
}

.tumblr {
	background-color: $tumblr-color;
	&:hover {
		background-color: darken($tumblr-color, 10%);
	}
}

.twitter {
	background-color: $twitter-color;
	&:hover {
		background-color: darken($twitter-color, 10%);
	}
}

.vimeo {
	background-color: $vimeo-color;
	&:hover {
		background-color: darken($vimeo-color, 10%);
	}
}

.linkedin {
	background-color: $linkedin-color;
	&:hover {
		background-color: darken($linkedin-color, 10%);
	}
}

.portfolio {
	background-color: $rose;
	&:hover {
		background-color: darken($rose, 10%);
	}
}