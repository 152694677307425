

.header {
	padding: 50px 0px;

	.page-logo {
		float: left;
		margin-top: 0px;
		margin-bottom: 0px;

		@media screen and (max-width: $screen-desktop) {
			font-size: 2.2em;
		}

		@media screen and (max-width: $screen-phone) {
			font-size: 1.6em;
		}
	}

	.menu-main-menu-container, .menu-secondary {
		float: right;
		margin-top: 15px;

		@media screen and (max-width: $screen-xs-max) {
			float: none;
		} 
	}
}

.main {
	padding: 50px 0px;
}
.banner {
	height: 350px;
}
.photo-set-roll {
	padding: 50px 0px;
}
.vimeo-embed {
	max-width: 100%;
}
.secondary {
	padding: 50px 0
}
.footer {
	padding: 30px 0px;
	background: lighten($gray-lighter,25%);
}
.page-slider {
		-webkit-transform: translateX(0);
		transform: translateX(0);
		-webkit-transition: all 0.3s;
		transition: transform 0.3s;
	&.mobileMenuIsOpen {
		-webkit-transform: translateX(-$mobile-menu-width);
		transform: translateX(-$mobile-menu-width);
	}
}

@media screen and (max-width: $screen-sm-min) {
	.page-logo {
		font-size: 2.2em;
	}
}

@media screen and (max-width: $screen-xs-min) {
	.page-logo {
		text-align: center;
	}

	.menu-main-menu-english-container, .menu-main-menu-francais-container {
		width: 100%;
		text-align: center;
	}

	.footer {
		span {
			width: 100%;
			text-align: center;
		}
	}
}