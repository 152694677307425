/* Mixins */

@mixin background-cover($image) {
	background-position: 0 50%;
	background-size: cover;
	background-attachment: fixed;
	background-image: url($image);
}

/* Background */

body {
	background-color: $rose-lighter;
}
.header {
	z-index: 1;
	@media screen and (max-width: $screen-tablet) {
		z-index: 2;
	}
}
.main-page {
	z-index: 1;
}
.header, .main {
	position: relative;
	background-color: #FFF;
}

/* Fonts */

h1, h2, h3, h4, h5, h6 {
	font-family: $title-font;
	color: $gray;
}
p, span, li, label {
	font-family: $main-font;
	color: $gray;
}
p {
	font-size: 1.2em;
	text-align: justify;
}
blockquote {
	font-family: $cursive-font;
	color: $gray;
}

a {
	color: $rose;
	&:hover {
		color: $gray;
		text-decoration: none;
	}
}

img {
	max-width: 100%;
	height: auto;
}

.text-spaced {
	line-height: 2.5em;
}
.title-color {
	color: $rose;
}

.btn {
	font-family: $title-font;
}
.widget-container {
	.btn {
		font-family: 'Open Sans Condensed', sans-serif !important;
	}
}

/* header */
.header {
 	box-shadow: 0px 3px 3px $gray-lighter;
 }
.page-logo {
	color: $rose;
}
/* Main menu */


.menu-main-menu-container, .menu-secondary {

	.menu {
		
	}
	.menu-item {

		a {
			color: $gray;
			&:hover {
				color: $rose;
			}

			@media screen and (max-width: $screen-desktop) {
				font-size: 1em;
			}

			@media screen and (max-width: $screen-xs-max) {
				color: #fff;
				font-size: 1.8em;
			}
		}
		&:after {
			content: "/";

			@media screen and (max-width: $screen-xs-max) {
				content: "";
			}
		}
		&:last-child:after {
			content: "";
		}
	}
	.current-menu-item {
		a {
			color: $rose;
		}
	}
}

/* Main */

.banner {
	@include background-cover("../images/ALEX8035.jpg");
	opacity: 0.75;
}

.project {
	@include background-cover("../images/ALEX8035.jpg");

	.project-content {
		padding: 100px 50px;
		background: lighten($gray-lighter,25%);
		background: rgba(255,255,255,0.7);
	}
}

/* footer */

.footer {
	a {
		color: $gray;
	}
}