.widget_polylang {
	position: absolute;
	top: 20px;

	@media screen and (max-width: $screen-xs-max) {
		position: relative;
		top: 0;
	}

	ul {
		margin: 0px;
		padding: 0px;
		list-style: none;
		text-align: right;

		@media screen and (max-width: $screen-xs-max) {
			text-align: left;
		}
	}
	.lang-item {
		display: inline-block;
		margin-left: 20px;
	}
}