.contact-form {
	label {
		//position: absolute;
	}
	input {
		width: 100% !important;
		margin-bottom: 20px !important;
		padding: 5px 10px;
		background-color: lighten($gray-lighter, 25%);;
		border: none;
	}
	input[type=text], input[type=email] {
		max-width: 100% !important;
	}

	textarea {
		width: 100% !important;
		height: 200px !important;
	}
}
.field-group {
	//position: relative;
}
