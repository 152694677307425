.photo-mosaic {

}
.photo-mosaic-item {
	position: relative;
	overflow: hidden;

	&:hover .photo-mosaic-image {
		opacity: 0.6;
	}

	&:hover .photo-mosaic-caption {
		bottom: 0;
	}
}
.photo-mosaic-image {
	opacity: 1;
	transition: all 0.3s ease;
}
.photo-mosaic-caption {
	position: absolute;
	bottom: -30px;
	width: 100%;
	padding: 0px 10px;
	background: rgba(255,255,255,0.5);
	transition: all 0.3s ease;
}