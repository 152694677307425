.comment {
	&.parent {
		border-bottom: 1px dotted $gray-light;
	}
	.avatar {
		border-radius: 50%;
	}
}

.input-wrap {
	label {
		display: block;
	}
	input[type=text] {
		width: 100%;
		padding: 5px 20px;
		background-color: lighten($gray-lighter, 25%);
		border: none;
	}
	textarea {
		width: 100%;
		min-height: 200px;
	}

	.button {
		padding: 5px 20px;
		background-color: $rose;
		color: #fff;
		font-family: $main-font;
		border: 1px solid $rose-darker;
		transition: all 0.3s ease;

		&:hover {
			background-color: $rose-darker;
		}
	}
} 